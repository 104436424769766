import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _41c45f1c = () => interopDefault(import('../pages/wrapper.vue' /* webpackChunkName: "wrapper" */))
const _2056bd0c = () => interopDefault(import('../pages/b2b-academy.vue' /* webpackChunkName: "b2b-academy" */))
const _cd863b84 = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "careers" */))
const _0837ed94 = () => interopDefault(import('../pages/iot-academy.vue' /* webpackChunkName: "iot-academy" */))
const _1be36537 = () => interopDefault(import('../pages/teltonika-high-tech-hill.com-landing.vue' /* webpackChunkName: "teltonika-high-tech-hill.com-landing" */))
const _10463ea2 = () => interopDefault(import('../pages/media-archive.vue' /* webpackChunkName: "media-archive" */))
const _73ff5b1d = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "news" */))
const _2673e8af = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "search" */))
const _928d5fe4 = () => interopDefault(import('../pages/dynamic-newsletter.vue' /* webpackChunkName: "dynamic-newsletter" */))
const _ea1bd202 = () => interopDefault(import('../pages/defaultContent.vue' /* webpackChunkName: "null" */))
const _43c793cc = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "contacts" */))
const _17157922 = () => interopDefault(import('../pages/mission-vision-values.vue' /* webpackChunkName: "mission-vision-values" */))
const _89ee1394 = () => interopDefault(import('../pages/policiesCertificates/index.vue' /* webpackChunkName: "policiesCertificates" */))
const _1ca5587a = () => interopDefault(import('../pages/b2b-academy-faq.vue' /* webpackChunkName: "b2b-academy-faq" */))
const _140a7d7f = () => interopDefault(import('../pages/iot-academy-faq.vue' /* webpackChunkName: "iot-academy-faq" */))
const _8d5b5624 = () => interopDefault(import('../pages/policiesCertificates/_slug.vue' /* webpackChunkName: "policiesCertificates" */))
const _7248b9d5 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "news" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus"},"page_id":14},
    name: "about-us-14___en"
  }, {
    path: "/academy",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Facademy","lt":"\u002Fakademija"},"page_id":30},
    name: "academy-30___en"
  }, {
    path: "/b2b-academy",
    component: _2056bd0c,
    meta: {"paths":{"en":"\u002Fb2b-academy","lt":"\u002Fb2b-akademija"},"page_id":23},
    name: "b2b-academy-23___en"
  }, {
    path: "/career",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fcareer","lt":"\u002Fkarjera"},"page_id":21},
    name: "career-21___en"
  }, {
    path: "/iot-academy",
    component: _0837ed94,
    meta: {"paths":{"en":"\u002Fiot-academy","lt":"\u002Fiot-akademija"},"page_id":22},
    name: "iot-academy-22___en"
  }, {
    path: "/knowledge",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fknowledge","lt":"\u002Fpazinimas"},"page_id":31},
    name: "knowledge-31___en"
  }, {
    path: "/lt",
    component: _1be36537,
    meta: {"paths":{"en":"\u002F","lt":"\u002F"},"page_id":27},
    name: "teltonika-high-tech-hill.com-landing-27___lt"
  }, {
    path: "/media-archive",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fmedia-archive","lt":"\u002Fmedia-archive"},"page_id":47},
    name: "media-archive-47___en"
  }, {
    path: "/newsroom",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos"},"page_id":8},
    name: "newsroom-8___en"
  }, {
    path: "/search",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska"},"page_id":45},
    name: "search-45___en"
  }, {
    path: "/subscribe",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok"},"page_id":66},
    name: "subscribe-66___en"
  }, {
    path: "/subscribe-old",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fsubscribe-old","lt":"\u002Fprenumeruoti-old"},"page_id":13},
    name: "subscribe-old-13___en"
  }, {
    path: "/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___en"
  }, {
    path: "/about-us/contacts",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai"},"page_id":19},
    name: "about-us-contacts-19___en"
  }, {
    path: "/about-us/mission-vision-values",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes"},"page_id":16},
    name: "about-us-mission-vision-values-16___en"
  }, {
    path: "/about-us/policies-certificates",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai"},"page_id":18},
    name: "about-us-policies-certificates-18___en"
  }, {
    path: "/b2b-academy/faq-b2b",
    component: _1ca5587a,
    meta: {"paths":{"en":"\u002Fb2b-academy\u002Ffaq-b2b","lt":"\u002Fb2b-akademija\u002Ffaq-b2b"},"page_id":35},
    name: "b2b-academy-faq-b2b-35___en"
  }, {
    path: "/iot-academy/faq-iot",
    component: _140a7d7f,
    meta: {"paths":{"en":"\u002Fiot-academy\u002Ffaq-iot","lt":"\u002Fiot-akademija\u002Ffaq-iot"},"page_id":34},
    name: "iot-academy-faq-iot-34___en"
  }, {
    path: "/lt/apie-mus",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus"},"page_id":14},
    name: "about-us-14___lt"
  }, {
    path: "/lt/akademija",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Facademy","lt":"\u002Fakademija"},"page_id":30},
    name: "academy-30___lt"
  }, {
    path: "/lt/b2b-akademija",
    component: _2056bd0c,
    meta: {"paths":{"en":"\u002Fb2b-academy","lt":"\u002Fb2b-akademija"},"page_id":23},
    name: "b2b-academy-23___lt"
  }, {
    path: "/lt/karjera",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fcareer","lt":"\u002Fkarjera"},"page_id":21},
    name: "career-21___lt"
  }, {
    path: "/lt/iot-akademija",
    component: _0837ed94,
    meta: {"paths":{"en":"\u002Fiot-academy","lt":"\u002Fiot-akademija"},"page_id":22},
    name: "iot-academy-22___lt"
  }, {
    path: "/lt/pazinimas",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fknowledge","lt":"\u002Fpazinimas"},"page_id":31},
    name: "knowledge-31___lt"
  }, {
    path: "/lt/media-archive",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fmedia-archive","lt":"\u002Fmedia-archive"},"page_id":47},
    name: "media-archive-47___lt"
  }, {
    path: "/lt/naujienos",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos"},"page_id":8},
    name: "newsroom-8___lt"
  }, {
    path: "/lt/paieska",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska"},"page_id":45},
    name: "search-45___lt"
  }, {
    path: "/lt/prenumeruok",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok"},"page_id":66},
    name: "subscribe-66___lt"
  }, {
    path: "/lt/prenumeruoti-old",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fsubscribe-old","lt":"\u002Fprenumeruoti-old"},"page_id":13},
    name: "subscribe-old-13___lt"
  }, {
    path: "/lt/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___lt"
  }, {
    path: "/lt/apie-mus/kontaktai",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai"},"page_id":19},
    name: "about-us-contacts-19___lt"
  }, {
    path: "/lt/apie-mus/misija-vizija-ir-vertybes",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes"},"page_id":16},
    name: "about-us-mission-vision-values-16___lt"
  }, {
    path: "/lt/apie-mus/politika-ir-sertifikatai",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai"},"page_id":18},
    name: "about-us-policies-certificates-18___lt"
  }, {
    path: "/lt/b2b-akademija/faq-b2b",
    component: _1ca5587a,
    meta: {"paths":{"en":"\u002Fb2b-academy\u002Ffaq-b2b","lt":"\u002Fb2b-akademija\u002Ffaq-b2b"},"page_id":35},
    name: "b2b-academy-faq-b2b-35___lt"
  }, {
    path: "/lt/iot-akademija/faq-iot",
    component: _140a7d7f,
    meta: {"paths":{"en":"\u002Fiot-academy\u002Ffaq-iot","lt":"\u002Fiot-akademija\u002Ffaq-iot"},"page_id":34},
    name: "iot-academy-faq-iot-34___lt"
  }, {
    path: "/",
    component: _1be36537,
    meta: {"paths":{"en":"\u002F","lt":"\u002F"},"page_id":27},
    name: "teltonika-high-tech-hill.com-landing-27___en"
  }, {
    path: "/lt/apie-mus/politika-ir-sertifikatai/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___lt"
  }, {
    path: "/about-us/policies-certificates/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___en"
  }, {
    path: "/lt/naujienos/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___lt"
  }, {
    path: "/newsroom/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
